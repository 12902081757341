import "../App.css";
import logo from "../assets/brand.png";
import button from "../assets/button.png";
import apple from "../assets/badge-apple-store.png";
import playStore from "../assets/badge-play-store.png";
import qrcode from "../assets/qcode.png";
import phoneapp from "../assets/phoneapp.png";
import background from "../assets/background.png";
import Halgai from "../assets/M-Halgai.png";
import React, { Component } from "react";
import ReactPlayer from "react-player";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Field, formInputData, formValidation } from 'reactjs-input-validator';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class App extends Component {
  constructor() {
    super();
    this.state = {
      data: {},
      isOpen:false,
      isOpen1:false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
 
  handleChange(event, inputValue, inputName, validationState, isRequired) {
    const value = (event && event.target.value) || inputValue;
    const { data } = this.state;
    data[inputName] = { value, validation: validationState, isRequired };
    this.setState({
      data,
    });
    // if you want access to your form data
    const formData = formInputData(this.state.data); // eslint-disable-line no-unused-vars
    // tells you if the entire form validation is true or false
    const isFormValid = formValidation(this.state.data); // eslint-disable-line no-unused-vars
  }
 
  handleSubmit(event) {
    event.preventDefault();
    // const isFormValid = formValidation(this.state.data);
 
    // if (isFormValid) {
    //   // do anything including ajax calls
    //   this.setState({ callAPI: true });
    // } else {
    //   this.setState({ callAPI: true, shouldValidateInputs: !isFormValid });
    // }
    this.setState({isOpen1:false})
    toast.success(
      <div style={{ display: "flex", alignItems: "center" }}>
        Бүртгэгдлээ
      </div>
    );
  
  }
 
  render() {
    const passwordValue = this.state.data.password && this.state.data.password.value;
 
  return (
    <div className="App">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          progressClassName="toastProgress"
          bodyClassName="toastBody"
        />
      <Modal
        open={this.state.isOpen}
        onClose={() => this.setState({isOpen:false})}
        styles={{
          modal: {
            maxWidth: "unset",
            width: "90%",
            padding: "unset",
          },
          overlay: {
            background: "rgba(0, 0, 0, 0.5)",
          },
          closeButton: {
            background: "#1a352e",
          },
        }}
        center
      >
        <ReactPlayer
          url="https://youtu.be/sRsaFYs4CQU"
          width="100%"
          height="calc(100vh - 100px)"
        />
      </Modal>
      <Modal
        open={this.state.isOpen1}
        onClose={() => this.setState({isOpen1:false})}
        styles={{
          modal: {
            maxWidth: "unset",
            width: "90%",
            padding: "unset",
          },
          overlay: {
            background: "rgba(0, 0, 0, 0.5)",
          },
          closeButton: {
            background: "#1a352e",
          },
        }}
        center
      >
        <div
          style={{ display: "flex",flexDirection:"column", alignItems: "center", height: "800px" ,marginTop:"10rem"}}
        >
          <div style={{  fontSize: "30px" }}>Бүртгүүлэх</div>
          <div style={{display:"flex",marginTop:"4rem"}}>
          <form >
          <Field
          required
          label="Lastname" name="Lastname" placeholder="Lastname"
          onChange={this.handleChange}
          value={this.state.data.Lastname}
          shouldValidateInputs={this.state.shouldValidateInputs}
            
      />
           <Field
          required
          label="Firstname" name="Firstname" placeholder="Firstname"
          onChange={this.handleChange}
          value={this.state.data.Firstname} 
          shouldValidateInputs={this.state.shouldValidateInputs}
      />
           <Field
          required
          label="Phone number" name="Phone" placeholder="Phone_number"
          onChange={this.handleChange}
          value={this.state.data.Phone}
          shouldValidateInputs={this.state.shouldValidateInputs}
      />
                <Field
          validator="isEmail" required
          label="Email" name="Email" placeholder="Email"
          onChange={this.handleChange}
          value={this.state.data.Email}
          shouldValidateInputs={this.state.shouldValidateInputs}
      />
      <div style={{display:"flex",justifyContent:"center"}}>
            <button
          type="submit" onClick={this.handleSubmit} className="btn btn-primary"
        >Sign up
        </button>
        </div>
          </form>
          </div>
        </div>
      </Modal>
      <div style={{display:"flex",justifyContent:"center"}}>
        <div className="header">
       
          <img src={logo} className="logoImg" />
         
          {/* <img src={logo} className="logoImg" /> */}
        </div>
        <div className="body" style={{zIndex:"1000"}}>
          <div className="bodyBackground" style={{zIndex:"1000"}}>
         
            <div className="section" style={{zIndex:"1000",cursor:"pointer"}}>
              <span
                style={{
                  marginBottom:"30px"
                }}
                className="imgM"
              >
               <img  src={Halgai} style={{
                  width:"20rem",   marginBottom:"30px"
                }}/>
              </span>
              <div className="videoText" style={{display:"flex",justifyContent:"center", color: "#D69A6D", fontWeight: "300" }}>
                Get the new Halgai. It’s an extra hand up your sleeve to help
                you get it all done.
              </div>
              <div className="videoContainer">
              <div className="videoButton" onClick={() => this.setState({isOpen:true})}>
                <img
                  src={button}
                  onClick={() => this.setState({isOpen:true})}
                  style={{ paddingRight: "5px" }}
                />
                Intro Video
              </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  backgroundColor: "#33363B",
                  zIndex:-1,
                  width: "300px",
                  padding: "20px",
                  borderRadius: "5px",
                  color: "white",
                  border:"1px solid #C48A5C"
                }}
                className="qrCode"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "18px",
                    fontWeight:"300",
                    color:"#C48A5C"
                  }}
                >
                  Get it now
                  <img
                    src={apple}
                    style={{ height: "35px", marginTop: "5px" }}
                  />
                  <img
                    src={playStore}
                    style={{ height: "35px", marginTop: "5px" }}
                  />
                </div>
                <div>
                  <img
                    src={qrcode}
                    style={{
                      height: "100px",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="logoMid">
            <img src={background} className="logoMidBack"  />
            </div>
            <div className="sectionOne"></div>
          </div>
       
        </div>
        
      </div>
     
    </div>
  );
                  }
}

