import React, { Component } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home"
import Download from "./pages/Dowload"
class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            component={(props) => (
              <Home/>
            )}
          />
           <Route
            exact
            path="/download"
            component={(props) => (
              <Download/>
            )}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}


export default App;
