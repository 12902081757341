import React, { Component } from "react";
import {
    isAndroid,
    isIOS
  } from "react-device-detect";


class Download extends Component {
  constructor(props) {
    super(props);
  }
  renderContent = () => {
    if (isAndroid) {
       window.location.href="https://play.google.com/store/apps/details?id=com.handsomeoldtree.idlefirefightertycoon&hl=en"
    }
    if (isIOS) {
         window.location.href="https://apps.apple.com/us/app/talking-ginger-2/id623158699"
    }
    return <div className="download">
                <h1 style={{color:"white"}}>Download</h1>
        </div>
}
  render() {
    return this.renderContent();
  }
}


export default Download;
